import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import { useHistory } from "react-router";
import React, { useState, useEffect } from "react";
import apiService from "../services/api.service";

const CreateUser = () => {
  let history = useHistory();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [isEmergency, setIsEmergency] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [boat, setBoat] = useState(null);
  const [boats, setBoats] = useState([]);
  const [passwordMismatch, setPasswordMismatch] = useState(false);
  const [successfulRegister, setSuccessfulRegister] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {
    getBoatsList();
  }, []);

  useEffect(() => {
    if (passwordConfirm === "") {
      if (password !== "") {
        setPasswordMismatch(true);
      }
    }
    if (passwordConfirm === password) {
      setPasswordMismatch(false);
    } else {
      setPasswordMismatch(true);
    }
  }, [password, passwordConfirm]);

  const getBoatsList = async () => {
    let boatsList = await apiService.getBoats();
    setBoats(boatsList);
  };

  const onPasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const onUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const onPasswordConfirmChange = (e) => {
    setPasswordConfirm(e.target.value);
  };
  const onfirstnameChange = (e) => {
    setFirstname(e.target.value);
  };
  const onLastNameChange = (e) => {
    setLastname(e.target.value);
  };
  const onIsEmergencyChange = (e) => {
    setIsEmergency(e.target.checked);
  };
  const onIsAdminChange = (e) => {
    setIsAdmin(e.target.checked);
  };
  const onBoatChange = (e) => {
    setBoat(e.target.value);
  };

  const populateBoatSelect = () => {
    if (!boats.data) {
      return;
    }
    let elements = [];
    boats.data.forEach((boat) => {
      elements.push(<option value={boat.id}>{boat.name}</option>);
    });
    return elements;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    await apiService
      .createUser(
        username,
        password,
        isAdmin,
        isEmergency,
        firstname,
        lastname,
        boat
      )
      .then(() => {
        setFormSubmitted(true);
      })
      .catch((error) => {
        console.log(error)
        setFormSubmitted(true);
        setSuccessfulRegister(false);
      });
      resetForm()
  };

  const displayAlert = (e) => {
    if (formSubmitted) {
      if (successfulRegister) {
        return (
          <Alert variant="success">
            La registrazione è avvenuta con successo
          </Alert>
        );
      } 
      else {
        return (
          <Alert variant="danger">
            È avvenuto un errore durante la registrazione
          </Alert>
        );
      }
    }
  };

  const resetForm = () =>{
    setUsername("");
    setPassword("");
    setPasswordConfirm("");
    setFirstname("");
    setLastname("");
    setIsAdmin(false);
    setIsEmergency(false);
    setBoat(null);
    setPasswordMismatch(false)
  }

  return (
    <Container>
      {displayAlert()}
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Username</Form.Label>
          <Form.Control
            required
            type="username"
            placeholder="Inserisci Nome Utente"
            value={username}
            onChange={onUsernameChange}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control
            required
            type="password"
            placeholder="Inserisci la password"
            value={password}
            onChange={onPasswordChange}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPasswordConfirm">
          <Form.Label>Conferma Password</Form.Label>
          <Form.Control
            required
            type="password"
            placeholder="Inserisci la password nuovamente"
            value={passwordConfirm}
            onChange={onPasswordConfirmChange}
            isValid={password === "" ? false : !passwordMismatch}
            isInvalid={passwordMismatch}
          />
          <Form.Control.Feedback type="invalid">
            Le password non corrispondono
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicAdmin">
          <Form.Check
            type="checkbox"
            label="Utente amministratore"
            checked={isAdmin}
            onChange={onIsAdminChange}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicEmergency">
          <Form.Check
            type="checkbox"
            label="Utente Emergenze"
            checked={isEmergency}
            onChange={onIsEmergencyChange}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicFirstName">
          <Form.Label>Nome</Form.Label>
          <Form.Control
            type="text"
            placeholder="Inserisci Nome (opzionale)"
            value={firstname}
            onChange={onfirstnameChange}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicLastName">
          <Form.Label>Cognome</Form.Label>
          <Form.Control
            type="text"
            placeholder="Inserisci Cognome (opzionale)"
            value={lastname}
            onChange={onLastNameChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Select
            className="mb-3"
            controlId="formBasicBoatSelect"
            value={boat}
            onChange={onBoatChange}
          >
            <option>Seleziona barca di appartenenza (opzionale)</option>
            {populateBoatSelect()}
          </Form.Select>
        </Form.Group>
        <Button variant="primary" type="submit" disabled={passwordMismatch}>
          Registra Utente
        </Button>
      </Form>
    </Container>
  );
};

export default CreateUser;
