import React, { useEffect, useState } from "react";
import { Col, Container, ListGroup, Modal, Row } from "react-bootstrap";
// import { useHistory } from 'react-router';
import apiService from "../services/api.service";
import { v4 as uuid } from "uuid";
import { Alert } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";

const EmergencyList = () => {
  // let history = useHistory()
  const [emergencies, setEmergencies] = useState([]);
  const [modalId, setModalId]= useState(-1)
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    getEmergencyList()
  }, []);

  const getEmergencyList = async() =>{
    let emergencylist = await apiService.getActiveEmergencies();
    setEmergencies(emergencylist);
  }

  const formatDate = (date) => {
    //todo: this code is duplicate with leafletmap
    return new Date(date).toLocaleString("it-IT", {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
  };

  const createModal = (id)=>{
    setModalId(id);
    setShowModal(true);
  }

  const closeModal = async (confirm) =>{
    if(confirm){
      await apiService.closeEmergency(modalId);
      getEmergencyList();
      setModalId(-1);
    }
    setShowModal(false);
    
  }

  const populate = () => {
    let elements = [];
    if (emergencies.length > 0) {
      emergencies.forEach((emergency) => {
        elements.push(
          <ListGroup.Item key={uuid()}>
            <Container>
              <Row className='mt-2'>
                <Col>Barca: {emergency.boat.name}</Col>
                <Col className="justify-content-end">
                  <Button as={Link} to={'/boats/'+emergency.boat.id+"/map"} className="button-map">Vai alla mappa</Button>
                </Col>
              </Row>
              <Row className='mt-2'>
                <Col>Inizio emergenza = {formatDate(emergency.start)}</Col>
                <Col className="justify-content-end">
                  <Button variant="warning" className="button-map" onClick={() => createModal(emergency.boat.id)}>
                    Chiudi emergenza
                  </Button>
                </Col>
              </Row>
            </Container>
          </ListGroup.Item>
        );
      });
      return <ListGroup>{elements}</ListGroup>;
    } else {
      //no active emergencies
      elements.push(
        <Alert variant="info" key={uuid()}>
          Non ci sono emergenze attive
        </Alert>
      );
      return elements;
    }
  };
  return (
    <Container>
    <Modal show = {showModal} onHide={() => closeModal(false)}>
    <Modal.Header closeButton>
          <Modal.Title>Termina emergenza</Modal.Title>
        </Modal.Header>
        <Modal.Body>Stai per terminare l'emergenza per la barca, sei sicuro?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>closeModal(false)}>
            Annulla
          </Button>
          <Button variant="danger" onClick={()=>closeModal(true)}>
            Termina emergenza
          </Button>
        </Modal.Footer>
    </Modal>
    <Container className="cont">
    <div class="title"><p class="title-page">Emergenze</p><p class="title-description">Lista emergenze attive</p></div>
    </Container>
      {populate()}
    </Container>
  );
};

export default EmergencyList;
