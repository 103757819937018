import axios from "axios";
import authHeader from "./auth-header";
import { API_URL } from "../config";

class ApiService {
  //   getPublicContent() {
  //     return axios.get(API_URL + 'all');
  //   }

  //   getUserBoard() {
  //     return axios.get(API_URL + 'user', { headers: authHeader() });
  //   }

  //   getModeratorBoard() {
  //     return axios.get(API_URL + 'mod', { headers: authHeader() });
  //   }

  //   getAdminBoard() {
  //     return axios.get(API_URL + 'admin', { headers: authHeader() });
  //   }

  async createUser(
    username,
    password,
    isAdmin,
    isEmergency,
    firstname,
    lastname,
    boatId
  ) {
    const params = {
        "username": username,
        "password": password,
        "is_admin": isAdmin,
        "is_emergency": isEmergency,
        "boat_id": boatId
    }
    if (firstname !== "") params["first_name"] =  firstname;
    if (lastname !== "") params["last_name"] = lastname;
    return await axios.post(API_URL+'/users', params, { headers: authHeader() });
  }

  async getBoats() {
    return await axios.get(API_URL + "/boats", { headers: authHeader() });
  }

  async getBoatCoords(boatId) {
    return await axios.get(API_URL + "/boats/" + boatId + "/positions", {
      headers: authHeader(),
    });
  }

  async getActiveEmergencies() {
    let response = await axios.get(API_URL + "/boats/emergencies", {
      headers: authHeader(),
    });
    return response.data;
  }

  async getOldEmergencies() {
    let response = await axios.get(API_URL + "/boats/emergencies/old", {
      headers: authHeader(),
    });
    return response.data;
  }

  async closeEmergency(boatId) {
    return await axios.post(
      API_URL + "/boats/" + boatId + "/emergency/end",
      {},
      { headers: authHeader() }
    );
  }

  async getFishLot(lotuuid){
    return axios.get(API_URL + "/lots/" + lotuuid, {headers: authHeader()});
  }

  async getUserById(id){
    return axios.get(API_URL+'/users/'+id, { headers: authHeader() });
  }

  async editUser(
    username,
    password = null,
    firstname,
    lastname,
    boat_id,
    is_emergency,
    is_admin,
    id
  ) {
    const params = {
        "username": username,
        "is_admin": is_admin,
        "is_emergency": is_emergency,
        "boat_id": boat_id,
        "first_name": firstname,
        "last_name": lastname
    }
    if (password != "") params['password'] = password
    

    return await axios.put(API_URL+'/users/'+id, params, { headers: authHeader() });
  }

  async getUsers(){
    return axios.get(API_URL+'/users', { headers: authHeader() });
  }
}

export default new ApiService();
