import React, { useEffect, useState } from "react";
import { Col, Container, ListGroup, Modal, Row } from "react-bootstrap";
// import { useHistory } from 'react-router';
import apiService from "../services/api.service";
import { v4 as uuid } from "uuid";
import { Alert } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import "./boats.css";

const BoatsList = () => {
  // let history = useHistory()
  const [boats, setBoats] = useState([]);
  
  useEffect(() => {
    getBoatsList()
  }, []);

  const getBoatsList = async() =>{
    let boatsList = await apiService.getBoats();
    setBoats(boatsList);
  }

  const populate = () => {
    let elements = [];
    if (boats.data) {
      boats.data.forEach((boat) => {
        elements.push(
          <ListGroup.Item key={uuid()} className="list-item">
            <Container>
              <Row className='riga-barca'>
                <Col>Barca: {boat.name}</Col>
                <Col>
                  <Button as={Link} to={'/boats/'+boat.id+"/map"} className="button-map">Vai alla mappa</Button>
                </Col>
              </Row>
            </Container>
          </ListGroup.Item>
          
        );
      });
      return <ListGroup>{elements}</ListGroup>;
    } else {
      //no active emergencies
      elements.push(
        <Alert variant="info" key={uuid()}>
          Non ci sono barche registrate
        </Alert>
      );
      return elements;
    }
  };
  return (
    <Container>
    {/* <Modal show = {showModal} onHide={() => closeModal(false)}>
    <Modal.Header closeButton>
          <Modal.Title>Termina emergenza</Modal.Title>
        </Modal.Header>
        <Modal.Body>Stai per terminare l'emergenza per la barca, sei sicuro?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>closeModal(false)}>
            Annulla
          </Button>
          <Button variant="danger" onClick={()=>closeModal(true)}>
            Termina emergenza
          </Button>
        </Modal.Footer>
    </Modal> */}
      <Container className="cont">
        <div class="title"><p class="title-page">Barche</p><p class="title-description">Lista barche</p></div>
      </Container>
      {populate()}
    </Container>
  );
};

export default BoatsList;
