import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import authService from "../services/auth.service";
import { useHistory } from "react-router";
import "./login.css";

const Login = (setLoginStatus) => {
  let history = useHistory()
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [successfulLogin, setSuccessfulLogin] = useState(null);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const onPasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const onUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await authService.login(username, password);
      history.push("/about", {login:true})
    } catch (error) {
      setSuccessfulLogin(false);
      setFormSubmitted(true)
    }
  };

  const displayAlert = (e) => {
    if (formSubmitted) {
      return successfulLogin ? (
        null
      ) : (
        <Alert variant="danger" style={{fontFamily:"Montserrat" }} >Le credenziali non sono corrette</Alert>
      );
    }
  };

  return (
    <Container className="cont">
      <div class="title"><p class="title-page">Accedi</p><p class="title-description">Compila i seguenti campi</p></div>
      {displayAlert()}
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label className="sub-login">Username</Form.Label>
          <Form.Control className="sub-login-ph"
            type="username"
            placeholder="Inserisci il nome utente"
            value={username}
            onChange={onUsernameChange}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label className="sub-login">Password</Form.Label>
          <Form.Control className="sub-login-ph"
            type="password"
            placeholder="Inserisci la password"
            value={password}
            onChange={onPasswordChange}
          />
        </Form.Group>
        {/* <Form.Group className="mb-3" controlId="formBasicCheckbox">
          <Form.Check type="checkbox" label="Check me out" />
        </Form.Group> */}
        <Button className="button" variant="default" type="submit">
          Accedi
        </Button>
      </Form>
    </Container>
  );
};

export default Login;
