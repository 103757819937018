import { useMap } from "react-leaflet"

const MapCenterer = (props) =>{
    const map = useMap()
    map.fitBounds(props.coords, {padding: [400,400]})
    if(props.zoom){
        map.setZoom(13)
    }
    return null
}

export default MapCenterer