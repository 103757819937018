import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import React, { useState, useCallback, useEffect } from "react";
import apiService from "../services/api.service";
import { Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";

const EditUser = () => {
  const [user, setUser] = useState();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [successfulRegister, setSuccessfulRegister] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [isEmergency, setIsEmergency] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [boat, setBoat] = useState(null);
  const [boats, setBoats] = useState();

  const [errorMessage, setErrorMessage] = useState("");

  let history = useHistory()
  let {id} =useParams()

  useEffect(() => {
    getUser()
    getBoatsList();
  }, []);


  useEffect(() =>{
    if(user){
      console.log(user)
      setUsername(user.data.username);
      setFirstname(user.data.first_name);
      setLastname(user.data.last_name);
      setIsAdmin(user.data.is_admin);
      setIsEmergency(user.data.is_emergency);
      setBoat(user.data.boat?.id)
    }
  }, [user])

  const getUser = async() =>{
    let user = await apiService.getUserById(id);
    setUser(user);
  }

  const getBoatsList = async () => {
    let boatsList = await apiService.getBoats();
    setBoats(boatsList);
  };

  const populateBoatSelect = () => {
    if (!boats?.data) {
      return;
    }
    let elements = [];
    boats.data.forEach((boat) => {
      elements.push(<option value={boat.id}>{boat.name}</option>);
    });
    return elements;
  };

  const checkPasswordMismatch = () => {
    if (passwordConfirm === "") {
      if (password !== "") {
        return true
      }
    }
    if (passwordConfirm === password) {
      return false
    } else {
      return true;
    }
  }

  const onPasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const onUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const onPasswordConfirmChange = (e) => {
    setPasswordConfirm(e.target.value);
  };
  const onfirstnameChange = (e) => {
    setFirstname(e.target.value);
  };
  const onLastNameChange = (e) => {
    setLastname(e.target.value);
  };
  const onIsAdminChange = (e) => {
    setIsAdmin(e.target.checked);
  };
  const onIsEmergencyChange = (e) => {
    setIsEmergency(e.target.checked);
  };
  const onBoatChange = (e) => {
    setBoat(e.target.value);
  };


  const handleSubmit = async (e) => {
    setButtonDisabled(true);
    setErrorMessage("");
    e.preventDefault();
    await apiService.editUser(
      username, password, firstname, lastname, boat, isEmergency, isAdmin, id
    )
      .then(() => {
        history.push("/about", {"successReason":"Modifica utente avvenuta con successo"})
        // setFormSubmitted(true);
        // setSuccessfulRegister(true);
      })
      .catch((error) => {
        console.log(error)
        setFormSubmitted(true);
        setSuccessfulRegister(false);

        if(error.response){
          if(error.response.data.detail){
            if(error.response.data.detail === 'Username already exists'){
              setErrorMessage("Questa email risulta già registrata, se non riesci ad effettuare l'accesso con i tuoi dati, contatta info@ceformedsrl.it")
            }
          }
        }
      });

  };


  const displayAlert = (e) => {
    if (formSubmitted) {
      if (successfulRegister) {
        return (
          <Alert variant="success">
            La modifica è avvenuta con successo.
          </Alert>
        );
      }
      else {
        if(errorMessage!= ""){
          let msg = errorMessage;
        return (
          <Alert variant="danger">
            {msg}
          </Alert>
        );
        }
        else{
          return (
            <Alert variant="danger">
              Errore durante la registrazione.
            </Alert>
          );
        }
      }
    }
  };

  // const resetForm = () => {
  //   setUsername("");
  //   setPassword("");
  //   setPasswordConfirm("");
  //   setFirstname("");
  //   setLastname("");
  //   setCodiceFiscale("");
  //   setButtonDisabled(false);
  //   setCroas("Sicilia")
  //   setIsEmergency("")
  // }

  return (
    <Container>
      {displayAlert()}
      <Form onSubmit={handleSubmit} className="mt-3" md="2">
        <h2 className="mb-5 mt-5" style={{ textAlign: "center" }}>modifica utente</h2>
        <Row className="mb-3 justify-content-md-center">
          <Form.Group as={Col} md="5" controlId="formBasicFirstName">
            <Form.Label>Nome</Form.Label>
            <Form.Control
              type="text"
              placeholder="Inserisci nome"
              value={firstname}
              onChange={onfirstnameChange}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3 justify-content-md-center">
          <Form.Group as={Col} md="5" controlId="formBasicLastName">
            <Form.Label>Cognome</Form.Label>
            <Form.Control
              type="text"
              placeholder="Inserisci cognome"
              value={lastname}
              onChange={onLastNameChange}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3 justify-content-md-center">
          <Form.Group as={Col} md="5" controlId="formBasicEmail">
            <Form.Label>Username</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Inserisci Username"
              value={username}
              onChange={onUsernameChange}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3 justify-content-md-center">
          <Form.Group as={Col} md="5" controlId="formBasicPassword">
            <Form.Label>Crea Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Inserisci la password"
              value={password}
              onChange={onPasswordChange}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3 justify-content-md-center">
          <Form.Group as={Col} md="5" controlId="formBasicPasswordConfirm">
            <Form.Label>Conferma Password</Form.Label>
            <Form.Control
              required= {password!==""}
              type="password"
              placeholder="Inserisci nuovamente la password"
              value={passwordConfirm}
              onChange={onPasswordConfirmChange}
              isValid={password === "" ? false : !checkPasswordMismatch}
              isInvalid={checkPasswordMismatch()}
            />
            <Form.Control.Feedback type="invalid">
              Le password non corrispondono
            </Form.Control.Feedback>
          </Form.Group>
        </Row>

        <Row className="mb-3 justify-content-md-center">
        <Form.Group as={Col} md="5">
          <Form.Check
            type="checkbox"
            label="Utente amministratore"
            checked={isAdmin}
            onChange={onIsAdminChange}
          />
        </Form.Group>
        </Row>

        <Row className="mb-3 justify-content-md-center">
        <Form.Group as={Col} md="5">
          <Form.Check
            type="checkbox"
            label="Utente Emergenze"
            checked={isEmergency}
            onChange={onIsEmergencyChange}
          />
        </Form.Group>
        </Row>


        <Row className="mb-3 justify-content-md-center">
          <Form.Group as={Col} md="5">
          <Form.Select
            className="mb-3"
            controlId="formBasicBoatSelect"
            value={boat}
            onChange={onBoatChange}
          >
            <option>Seleziona barca di appartenenza (opzionale)</option>
            {populateBoatSelect()}
          </Form.Select>
        </Form.Group>
        </Row>
        <Row className="mb-3 justify-content-md-center">
          <Col md="5">
            <Button
              className="col-md-12"
              size="lg"
              variant="primary"
              type="submit"
              disabled={checkPasswordMismatch() || buttonDisabled}>
              modifica
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default EditUser;
