import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { MapContainer, TileLayer, Marker, Polyline, Tooltip, CircleMarker} from 'react-leaflet'
import "./leafletMap.component.css"
import apiService from '../services/api.service'
import { Alert, Container } from 'react-bootstrap'
import utils from '../utils'
import MapCenterer from '../components/Map/mapCenterer'


const LeafletMap = () => {
    let [alertContent, setAlertContent]=useState(null)

    let {id} = useParams()
    let [coords, setCoords] = useState([])
    let [timestamps, setTimestamps] = useState([])

    let [circleMarkers, setCircleMarkers] = useState([])//todo: refractor all states into one
    let [polylines, setPolylines] = useState([])
    let [lastMarker, setLastMarker] = useState([])
    let [mapCenterer, setMapCenterer] = useState([])


    

    useEffect(() =>{
        const parseCoords = async () =>{
            let response = await apiService.getBoatCoords(id).catch((error)=>{
                if(error.response.data.detail){
                    setAlertContent(error.response.data.detail)
                }
                else{
                    setAlertContent("Errore nel reperimento delle coordinate")
                }
            })
            if (!response){
                return;
            }
            let apiCoords = response.data
            let parsedCoords = []
            let parsedTimestamps = []
            if(apiCoords.length < 1){
                setAlertContent("Non ci sono coordinate registrate nelle ultime 12 ore")
            }
            apiCoords.forEach(coord => {
                parsedCoords.push([coord.lat, coord.lon])
                parsedTimestamps.push(coord.time)
            });
            setCoords(parsedCoords)
            setTimestamps(parsedTimestamps)
        }
        setTimeout(() => {
            window.location.reload()
        }, 65000);
        parseCoords()
    }, [id])

    useEffect(() =>{
        let i = 0
        let markerlist = []
        let polylineslist = []

        if (!coords[0] || !timestamps[0]){
            return
        }

        coords.forEach((coord, index) => {
            markerlist.push(
            <CircleMarker center={coord} radius='20' pathOptions={{stroke:false, fillOpacity:0}} key = {i++}>
                <Tooltip>
                lat = {coord[0]}, lon = {coord[1]}, data = {utils.formatDate(timestamps[index])}
                </Tooltip>
            </CircleMarker>
            )
        });

        polylineslist.push(
            <Polyline positions = {coords} key= {i++}/>
        )

        setMapCenterer(
            <MapCenterer coords = {coords}/>
        )
        setLastMarker(
            <Marker position = {coords[0]}>
                <Tooltip>
                    lat = {coords[0][0]}, lon = {coords[0][1]} Data= {utils.formatDate(timestamps[0])}
                </Tooltip>
            </Marker>
        )
        setCircleMarkers(markerlist)
        setPolylines(polylineslist)
    }, [timestamps, coords])

    return(
        <div>
        <Container className='mt-2'>
        {alertContent
        ?<Alert variant='danger'> {alertContent} </Alert>
        :null}
        </Container>
        <MapContainer center={[0,0]} zoom={13} scrollWheelZoom={true} className='fullscreenMap'>
            <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <TileLayer
                attribution='nautical Map data: &copy; <a href="http://www.openseamap.org">OpenSeaMap</a> contributors'
                url="https://tiles.openseamap.org/seamark/{z}/{x}/{y}.png"
            />
            {lastMarker}
            {circleMarkers}
            {polylines}
            {mapCenterer}
        </MapContainer>
        </div>

    )
}


export default LeafletMap