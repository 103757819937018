import React, { useEffect, useState } from "react";
import { Col, Container, ListGroup, Row } from "react-bootstrap";
// import { useHistory } from 'react-router';
import apiService from "../services/api.service";
import { v4 as uuid } from "uuid";
import { Alert } from "react-bootstrap";

const OldEmergencyList = () => {
  // let history = useHistory()
  const [emergencies, setEmergencies] = useState([]);

  useEffect(() => {
    getEmergencyList()
  }, []);

  const getEmergencyList = async() =>{
    let emergencylist = await apiService.getOldEmergencies();
    setEmergencies(emergencylist);
  }

  const formatDate = (date) => {
    //todo: this code is duplicate with leafletmap
    return new Date(date).toLocaleString("it-IT", {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
  };

  const populate = () => {
    let elements = [];
    if (emergencies.length > 0) {
      emergencies.forEach((emergency) => {
        elements.push(
          <ListGroup.Item key={uuid()}>
            <Container>
              <Row className='mt-2'>
                <Col>Barca: {emergency.boat.name}</Col>
                <Col className="justify-content-end">
                </Col>
              </Row>
              <Row className='mt-2'>
                <Col>Inizio emergenza = {formatDate(emergency.start)}</Col>
                <Col>Fine emergenza = 
                {emergency.end
                ?formatDate(emergency.end)
                :" emergenza in corso"
                }</Col>
              </Row>
            </Container>
          </ListGroup.Item>
        );
      });
      return <ListGroup>{elements}</ListGroup>;
    } else {
      //no active emergencies
      elements.push(
        <Alert variant="info" key={uuid()}>
          Non ci sono emergenze
        </Alert>
      );
      return elements;
    }
  };

  return (
    <Container className="cont">
      <div class="title"><p class="title-page">Emergenze passate</p><p class="title-description">Ultime 20 emergenze</p></div>
      {populate()}
    </Container>
  );
};

export default OldEmergencyList;
