import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link } from "react-router-dom";
import { useHistory, useLocation } from "react-router";
import authService from "../../services/auth.service";
import { v4 as uuid } from "uuid";
import "./index.css";

const BootstrapNavbar = (props) => {
  //using location here forces render on location change
  // eslint-disable-next-line
  let location = useLocation();
  let history = useHistory();
  const logout = () => {
    authService.logout();
    props.setLoginStatus({})
    history.push("/about")
  }

  const populateNavbar = () => {
    let elements = [];
    elements.push(
      <Nav className="me-auto" key={uuid()}>
      {/* <Nav.Link as={Link} to="/about">
        Home
      </Nav.Link> */}
      {props.loginStatus['sub'] ? <Nav.Link className="link-2" as={Link} to="/boats/self/map">Mappa barca</Nav.Link> : null}
      
      {populateFirstSection()}
    </Nav>
    );
    if(!props.loginStatus['sub']){//no user is logged
      elements.push(
        <Nav key={uuid()}>
          <Nav.Link className="nav-link" as={Link} to="/login">Area Riservata</Nav.Link>
        </Nav>
      );
    }
    else{//some user is logged
      elements.push(
        <Nav key={uuid()}>
          <NavDropdown title={props.loginStatus['sub']}>
          <NavDropdown.Item className="nav-link" as={Link} to="/user">Profilo</NavDropdown.Item>
            {props.loginStatus['is_admin']
            ?<div>
            <NavDropdown.Item className="nav-link" as={Link} to="/usercreate">Crea Utente</NavDropdown.Item>
            <NavDropdown.Item className="nav-link" as={Link} to="/users">Lista utenti</NavDropdown.Item>
            </div>
            :null}
            <NavDropdown.Item className="nav-link" onClick={logout}>Esci</NavDropdown.Item>
          </NavDropdown>
        </Nav>
      )
    }

    return elements;
  }

  const populateFirstSection = () =>{
    let elements = [];
    if(props.loginStatus['is_emergency']){
      elements.push(
      <NavDropdown title="Barche" id="basic-nav-dropdown" key={uuid()}>
        <NavDropdown.Item className="nav-link" as={Link} to="/boats">Lista Barche</NavDropdown.Item>
        <NavDropdown.Item className="nav-link" as={Link} to="/boats/emergencies">Lista emergenze attive</NavDropdown.Item>
        <NavDropdown.Item className="nav-link" href="/boats/emergencies/old">Lista emergenze passate</NavDropdown.Item>
        {/* <NavDropdown.Divider />
        <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item> */}
      </NavDropdown>
      );
    }
    return elements
  }

  return (
    
    <Navbar bg="color" expand="lg">
      <Container>
        
        <Navbar.Brand as={Link} to="/about">
        <h3 class="site-logo"><img class="logo" src="/istituzionali.png"></img> </h3>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          {populateNavbar()}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default BootstrapNavbar;
