import React from 'react';
import Container from 'react-bootstrap/Container';
import  Alert from 'react-bootstrap/Alert';
import { useHistory } from 'react-router';
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "./about.css"
import { Link } from "react-router-dom";
import { Col } from 'react-bootstrap';
import { Row } from 'react-bootstrap';


const About = (props) => {
	let history = useHistory()
	const checkLogin = () => {
		if (history.location.state){
			if (history.location.state['login']){
			return <Alert variant="success" style={{fontFamily:"Montserrat" }}>Login effettuato con successo</Alert>;
			
			}
		}
	}
return (
	<Container className="cont">
	{checkLogin()}
	{props.loginStatus["sub"] ?
    	<div class="title"><p class="title-page">Home</p><p class="title-description">Scegli cosa fare</p></div>
		: 		<Row className="margin-row">
			<Button className="button-2" variant="default" type="submit" as={Link} to="/login">
				<Col className="col1"><img src="green.png" className="img-button"></img>Accedi all'area riservata</Col>
			</Button>
		</Row>}
	{
		props.loginStatus["is_emergency"]?
	<Container className="cont-2">
		<Row className="margin-row">
			<Button className="button-2" variant="default" type="submit" as={Link} to="/boats">
				<Col className="col1"><img src="boat.png" className="img-button"></img>Lista Barche</Col>
			</Button>
		</Row>
		<Row className="margin-row">
			<Button className="button-2" variant="default" type="submit" as={Link} to="/boats/emergencies">
				<Col className="col1"><img src="eme.png" className="img-button"></img>Lista emergenze attive</Col>
			</Button>
		</Row>
		<Row className="margin-row">
			<Button className="button-2" variant="default" type="submit" as={Link} to="/boats/emergencies/old">
				<Col className="col1"><img src="noem.png" className="img-button"></img>Lista emergenze passate</Col>
			</Button>
		</Row>
		
	</Container>
	:null
}

{
	!props.loginStatus["is_emergency"] && props.loginStatus["sub"] ?
	<Container className="cont-2">
		<Row className="margin-row">
	<Button className="button-2" variant="default" type="submit" as={Link} to="/boats/self/map">
			<Col className="col1"><img src="map.png" className="img-button"></img>Vai a Mappa Barche</Col>
		</Button>
		</Row>
</Container>
:null
}
	<Container className="cont-2">
	<Row className="margin-row" style={{marginTop:"7px"}}>
 		<a className="link-img" href="http://cogepamazara.it/"><img src="istituzionali.png" className="img-istituzionali"></img></a>
	</Row>
	</Container>
	
    </Container>
);
};

export default About;
