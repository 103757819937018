import { useCallback, useEffect } from "react"
import { useLocation } from "react-router";

const AuthVerify = (props) =>{
    let location = useLocation()

    const decodeJwt = (token) => {
      var base64Url = token.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
  
      return JSON.parse(jsonPayload);
    }

    const parseJwt = (token) => {
        try {
          return JSON.parse(Buffer.from(token.split('.')[1], 'base64'));
        } catch (e) {
          return null;
        }
      };
    const readLoginStatus =useCallback( (user) => {
      let decoded = decodeJwt(user.access_token);
      props.setLoginStatus(decoded);
    }, [props]);

    useEffect(() =>{
        const user = JSON.parse(localStorage.getItem('user'));
        if (user){
          if(!props.loginStatus['sub']){
            readLoginStatus(user)
          }
            const decodedJwt = parseJwt(user.access_token);
            if(decodedJwt.exp * 1000 < Date.now()){
                localStorage.removeItem('user');
                props.setLoginStatus({})
                //todo: redirect somewhere
            }
        }
    }, [location, props, props.loginStatus, readLoginStatus])
    

    return null
}

export default AuthVerify