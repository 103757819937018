import React, { useEffect, useState } from "react";
import { Col, Container, ListGroup, Modal, Row } from "react-bootstrap";
// import { useHistory } from 'react-router';
import apiService from "../services/api.service";
import { Link, useParams } from "react-router-dom";
import utils from "../utils";
import { MapContainer, Marker, TileLayer, Tooltip } from "react-leaflet";
import MapCenterer from "../components/Map/mapCenterer";

const FishLot = () => {
  const [fishLot, setFishLot] = useState();
  const [mapCenterer, setMapCenterer] = useState();

  let {lotuuid} = useParams()
  
  useEffect(() => {
    getFishLot()
  }, []);

  const getFishLot = async() =>{
    let lot = await apiService.getFishLot(lotuuid);
    lot = lot['data']
    setFishLot(lot);
    setMapCenterer(<MapCenterer zoom = {true} coords = {[[lot["coord_lat"], lot["coord_lon"]]]}></MapCenterer>)
  }

  const populate = () => {
      if(!fishLot){
          return
      }
      return <div>
        <div>Tipologia pesce: {fishLot['fish_type']}</div>
        <div>Peso: {fishLot['weight']} Kg</div>
        <div>Data e ora creazione lotto: {utils.formatDate(fishLot['time'])}</div>
        <div>Peschereccio: {fishLot['boat']['name']}</div>
        <div>Fornitore: {fishLot['boat']['users'][0]['first_name']}</div>
        
        {fishLot['sensor_depth'] ? <div>
          <div>DATI SENSORI</div>
          <div>Profondità rilevazione: {fishLot['sensor_depth']}</div>
          <div>Temperatura: {fishLot['sensor_temp']}</div>
          <div>pH: {fishLot['sensor_pH']}</div>
        </div> : null
        }
        {fishLot['auction_link'] ? <div>Link asta: {fishLot['auction_link']}</div>:null}

        <MapContainer center={[0,0]} zoom={2} scrollWheelZoom={true} className='fullscreenMap'>
            <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <TileLayer
                attribution='nautical Map data: &copy; <a href="http://www.openseamap.org">OpenSeaMap</a> contributors'
                url="https://tiles.openseamap.org/seamark/{z}/{x}/{y}.png"
            />
            <Marker position = {[fishLot["coord_lat"], fishLot["coord_lon"]]}>
                <Tooltip>
                    lat = {fishLot['coord_lat']}, lon = {fishLot['coord_lon']}
                </Tooltip>
            </Marker>
            {mapCenterer}
        </MapContainer>
      </div>
  }
  return (
    <Container>
      <h1>Lotto {lotuuid}</h1>
      {populate()}
    </Container>
  );
};

export default FishLot;
