import './App.css';
import BootstrapNavbar from './components/BootstrapNavbar'
import About from './routes/about';
import Login from './routes/login'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import LeafletMap from './routes/leafletMap';
import AuthVerify from './routes/authVerify';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useState } from 'react';
import EmergencyList from './routes/emergencyList';
import OldEmergencyList from './routes/oldEmergencyList'
import BoatsList from './routes/boats'
import CreateUser from './routes/createUser';
import FishLot from './routes/fishlot';
import EditUser from './routes/editUser';
import UserList from './routes/userList';
import userEvent from '@testing-library/user-event';

function App() {
  const [loginStatus, setLoginStatus] = useState({})
  return (
    <div className='content'>
      <Router>
        
        <BootstrapNavbar loginStatus = {loginStatus} setLoginStatus = {setLoginStatus}/>
        
        <header>
          <img class="site-cover" src="/cover3.jpg"></img>
          <div class="position-title">
            <p class="page-title-description">Benvenuti su</p>
            <p class="page-title">Tracciabilità e sicurezza</p>
          </div>
        </header>

        <Switch>
          <Route path='/about' render={() => <About loginStatus={loginStatus}/>} />
          <Route path='/login' render={() => <Login setLoginStatus={setLoginStatus}/>} />
          <Route path='/usercreate' component={CreateUser}/>
          <Route path='/users/:id/edit' component={EditUser} />
          <Route path='/users/' component={UserList} />
          <Route path='/fishlots/:lotuuid' component={FishLot}/> 
          <Route path='/boats/:id/map' component={LeafletMap}/> 
          <Route path='/boats/emergencies/old' component={OldEmergencyList}/>
          <Route path='/boats/emergencies' component={EmergencyList}/>
          <Route path='/boats' component={BoatsList} />
          <Route path='/' render={() => <About loginStatus={loginStatus}/>} />

        </Switch>
        <AuthVerify setLoginStatus={setLoginStatus} loginStatus = {loginStatus}/>
      </Router>
    </div>
  );
}

export default App;
